import { RefObject } from 'react'
import getRefElement from 'lib/getRefElement'
import useEventListener from './useEventListener'

interface UseFocusOutside {
  listener: (event: Event) => void
  element: RefObject<HTMLElement>
}

const useFocusOutside = ({ listener, element }: UseFocusOutside): void => {
  const handleOutsideFocus = (event: Event) => {
    const el = getRefElement(element)
    if (!el || (el as HTMLElement).contains(event.target as Node)) return

    listener(event)
  }

  useEventListener({
    type: 'focusin',
    listener: handleOutsideFocus,
  })
}

export default useFocusOutside
