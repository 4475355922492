import QEV from './QEV'
import useQEVModal from './hooks/useQEVModal'

export { useQEVModal }

const LOCALSTORAGE_SEARCH_QEV_MODAL_KEY = 'EmCasaSearchQEVModal'
const LOCALSTORAGE_RECOMMENDATIONS_QEV_MODAL_KEY =
  'EmCasaRecommendationsQEVModal'
export {
  LOCALSTORAGE_SEARCH_QEV_MODAL_KEY,
  LOCALSTORAGE_RECOMMENDATIONS_QEV_MODAL_KEY,
}

export default QEV
