import { CSSProperties, FC, PropsWithChildren, useRef, useState } from 'react'
import classNames from 'classnames'
import { IconInfo, IconInfoCircle } from 'icons'
import Typography from 'components/Typography'
import Accordion from 'components/Accordion'
import Button, { TProps as TButtonProps } from 'components/Button'
import styles from './FAQ.module.scss'
import * as events from '../events'

export type TLoginButtonProps = {
  onClick: () => void
  label?: string
  props?: TButtonProps
}

export type TProps = {
  onExpand?: () => void
  loginButton?: TLoginButtonProps
  submitButton: {
    onClick: () => void
    label?: string
    props?: TButtonProps
  }
}

const FAQ: FC<PropsWithChildren<TProps>> = ({
  onExpand,
  loginButton,
  submitButton,
  children,
}) => {
  const [open, setOpen] = useState(false)
  const accordionRef = useRef<HTMLDivElement>(null)
  const accordionHeight = accordionRef?.current?.offsetHeight

  const className = classNames(styles.ecFAQ)
  const buttonTitle = open ? 'Esconder FAQ' : 'Mostrar FAQ'

  const handleExpand = () => {
    setOpen(!open)

    if (!open) {
      events.onFAQOpenQEV()
      if (onExpand) onExpand()
    }
  }

  return (
    <div className={styles.ecFAQContainer}>
      {children}
      <div className={className}>
        <div
          aria-hidden
          title={buttonTitle}
          onClick={handleExpand}
          data-testid="faqHeaderButton"
          className={styles.ecFAQButton}
        />
        <div className={styles.ecFAQHeader}>
          <div className={styles.ecFAQHeaderContainer}>
            <button
              type="button"
              title={buttonTitle}
              className={styles.ecFAQHeaderLink}
              onClick={handleExpand}
            >
              <IconInfoCircle
                width={24}
                height={24}
                color="pink500"
                title="Ícone: info"
              />
              Por que preencher o perfil?
            </button>
          </div>
          <div className={styles.ecFAQHeaderButtons}>
            {loginButton && (
              <Button
                className={styles.ecFAQHeaderSecondaryButton}
                onClick={() => {
                  events.onClickLoginButton()
                  loginButton.onClick()
                }}
                {...loginButton?.props}
              >
                {loginButton?.label || 'Já tenho perfil'}
              </Button>
            )}
            <Button
              className={styles.ecFAQHeaderPrimaryButton}
              theme="pink"
              onClick={submitButton.onClick}
              {...submitButton?.props}
            >
              {submitButton?.label || 'Avançar'}
            </Button>
          </div>
        </div>
        <div
          id="faq"
          className={styles.ecFAQAccordionWrapper}
          style={
            {
              '--height':
                open && accordionHeight ? `${accordionHeight}px` : '0px',
            } as CSSProperties
          }
        >
          <div ref={accordionRef} className={styles.ecFAQAccordion}>
            <div className={styles.ecFAQIconWrapper}>
              <IconInfo color="gray900" title="info" width={30} height={30} />
            </div>
            <Typography
              as="h3"
              variant="TitleSmall"
              bold
              className={styles.ecFAQTitle}
            >
              Por que preencher o perfil?
            </Typography>
            <div>
              <Accordion
                onExpand={(title) => events.onFAQAccordionOpenQEV(title)}
                items={[
                  {
                    title: 'Receba recomendações de imóveis incríveis.',
                    customElements: {
                      title: {
                        as: 'h4',
                      },
                    },
                    size: 'small',
                    content: (
                      <div className={styles.ecFAQAccordionContainer}>
                        <Typography>
                          Conheça imóveis do seu interesse sem precisar buscar
                          ativamente. E saiba em primeira mão quando publicarmos
                          novos imóveis no seu perfil.
                        </Typography>
                        <Typography>
                          Não enviaremos imóveis que não estejam na localização
                          ou faixa de preço que você quer. Respeitamos o que
                          você busca.
                        </Typography>
                        <Typography>
                          Além das recomendações de perfil, também receba
                          atualizações de imóveis que favoritou e imóveis
                          similares ao que favoritou.
                        </Typography>
                        <Typography>
                          Quanto mais utilizar a busca, melhor serão as
                          recomendações.
                        </Typography>
                      </div>
                    ),
                  },
                  {
                    title: 'Facilidade em ver imóveis recomendados.',
                    customElements: {
                      title: {
                        as: 'h4',
                      },
                    },
                    size: 'small',
                    content: (
                      <Typography>
                        Receba recomendações direto no seu WhatsApp e E-mail.
                        Enviamos fotos, principais informações e link para o
                        anúncio completo, caso queria ver todos os dados ou
                        agendar uma visita.
                      </Typography>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
