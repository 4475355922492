export const scrollToElement = (id: string, yOffset = 0) => {
  if (typeof window === 'undefined') return

  const element = document.getElementById(id)

  if (!element) return

  const yAxis =
    element.getBoundingClientRect().top + window.pageYOffset + yOffset

  window.scrollTo({ top: yAxis, behavior: 'smooth' })
}

export const delayFocus = (target: HTMLElement) => {
  const fakeInput = document.createElement('input')
  fakeInput.setAttribute('type', 'text')
  fakeInput.style.position = 'absolute'
  fakeInput.style.opacity = '0'
  fakeInput.style.height = '0'
  fakeInput.style.fontSize = '16px'

  document.body.prepend(fakeInput)
  fakeInput.focus()

  setTimeout(() => {
    target.focus()
    fakeInput.remove()
  }, 1000)
}
