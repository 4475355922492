import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import Button from 'components/Button'
import { toCurrency } from 'lib/number'
import Typography from 'components/Typography'
import { IconCheck, IconCircleMoneySign, IconPin } from 'icons'
import styles from '../QEV.module.scss'
import { TQEVVariables } from '../types'
import * as events from '../events'

type TProps = {
  data?: TQEVVariables
  button?: {
    label: string
    onClick: (successScreenData: TQEVVariables | undefined) => void
  }
  closeModal: () => void
}

const Success: FC<TProps> = ({ data, button, closeModal }) => {
  const { asPath } = useRouter()

  const getFormattedValues = (value?: TQEVVariables) => ({
    maxValue: toCurrency(value?.maxValue),
    city: value?.city as string,
    neighborhoods: value?.neighborhoods?.join(', ') as string,
  })

  const { maxValue, city, neighborhoods } = getFormattedValues(data)

  const onButtonClick = () => {
    events.onSuccessButtonQEV(asPath)
    if (button?.onClick) {
      button?.onClick(data)
    } else {
      closeModal()
    }
  }

  useEffect(() => {
    events.onSuccessQEV(data as TQEVVariables)
  }, [])

  return (
    <div className={styles.ecQEVResult}>
      <div className={styles.ecQEVResultContainer}>
        <div className={styles.ecQEVResultTop}>
          <div className={styles.ecQEVResultIconWrapper}>
            <IconCheck
              title="Ícone: check"
              height={32}
              width={32}
              color="grey900"
            />
          </div>
          <div className={styles.ecQEVResultTextContainer}>
            <Typography
              as="h2"
              variant="Title"
              bold
              className={styles.ecQEVResultTitle}
            >
              Perfil criado
            </Typography>
            <Typography as="p" variant="ParagraphSmall">
              Você começará a receber recomendações de imóveis com as seguintes
              características:
            </Typography>
          </div>
          <div className={styles.ecQEVResultInfoContainer}>
            <div className={styles.ecQEVResultInfoItem}>
              <div className={styles.ecQEVResultInfoItemIcon}>
                <IconCircleMoneySign
                  color="grey900"
                  title="Ícone: valor máximo"
                  width={27}
                  height={27}
                />
              </div>
              <Typography as="p">{maxValue}</Typography>
            </div>

            <div className={styles.ecQEVResultInfoItem}>
              <div className={styles.ecQEVResultInfoItemIcon}>
                <IconPin
                  color="grey900"
                  title="Ícone: localização"
                  width={27}
                  height={27}
                />
              </div>
              <Typography as="p">{city}</Typography>
            </div>

            <div className={styles.ecQEVResultInfoItem}>
              <div className={styles.ecQEVResultInfoItemIcon}>
                <IconPin
                  color="grey900"
                  title="Ícone: localização"
                  width={27}
                  height={27}
                />
              </div>
              <Typography as="p">{neighborhoods}</Typography>
            </div>
          </div>
        </div>

        <div className={styles.ecQEVResultBottom}>
          <Button
            theme="pink"
            className={styles.ecQEVResultButton}
            onClick={onButtonClick}
          >
            {button?.label || 'Ver imóveis no meu perfil'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Success
