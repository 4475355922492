import logger from 'lib/logger'
import { TQEVVariables } from './types'

type TChangeFocusParams = {
  name: string
  value: string | Array<string>
}

export const onLoadListingStepQEV = () => logger.action('you-setup-listing')

export const onFocusListingStepInputQEV = (
  name: TChangeFocusParams['name'],
  value: TChangeFocusParams['value'],
) => logger.action('you-setup-listing-focus', { name, value })

export const onLoadProfileStepQEV = () => logger.action('you-setup-profile')

export const onFocusProfileStepInputQEV = (
  name: TChangeFocusParams['name'],
  value: TChangeFocusParams['value'],
) => logger.action('you-setup-profile-focus', { name, value })

export const onClickNextStepQEV = () =>
  logger.action('you-setup-next', { type: 'listing' })

export const onSubmitQEV = () =>
  logger.action('you-setup-submit', { type: 'listing' })

export const onSendQEV = (data: TQEVVariables) =>
  logger.action('you-setup-send', { type: 'listing', ...data })

export const onSendSuccessQEV = (data: TQEVVariables) =>
  logger.action('you-setup-send-success', { type: 'listing', ...data })

export const onUpdateSendSuccessQEV = (data: TQEVVariables) =>
  logger.action('you-setup-update-send-success', { type: 'listing', ...data })

export const onSendErrorQEV = (error: string, data: TQEVVariables) =>
  logger.action('you-setup-send-error', { error, ...data })

export const onSuccessQEV = (data: TQEVVariables) =>
  logger.action('you-setup-success', { type: 'listing', ...data })

export const onSuccessButtonQEV = (url: string) =>
  logger.action('you-setup-success-button', { type: 'listing', url })

export const onLoadLoadingQEV = () =>
  logger.action('you-setup-loading', { type: 'listing' })

export const onFAQOpenQEV = () =>
  logger.action('you-setup-faq-open', { type: 'listing' })

export const onFAQAccordionOpenQEV = (name: string) =>
  logger.action('you-setup-faq-accordion', { type: 'listing', name })

export const onClickLoginButton = () => logger.action('you-setup-login-button')
