import { ChangeEvent, FC, useEffect, useMemo, useCallback } from 'react'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import {
  CitiesQuery,
  City,
  DistrictsQuery,
  DistrictsQueryVariables,
} from 'graphql/types'
import GET_DISTRICTS from 'graphql/queries/districts'
import { toCurrencyNoStyle, toValidNumber } from 'lib/number'
import { getFirstName } from 'lib/user'
import { orderCitiesByVolume } from 'lib/cities'
import { TFormValues } from '../types'
import GET_CITIES from 'graphql/queries/cities'
import FormElement from 'components/form/FormElement'
import Input from 'components/form/Input'
import Typography from 'components/Typography'
import Typeahead from 'components/Typeahead'
import { IconPin } from 'icons'
import * as events from '../events'
import FAQ, { TLoginButtonProps } from '../components/FAQ'
import styles from '../QEV.module.scss'

export type TProps = {
  loginButton?: TLoginButtonProps
  goToNextStep: () => void
  createQEVError?: ApolloError
  createQEVLoading?: boolean
  hasSearchPreferences?: boolean | null
}

const AboutYou: FC<TProps> = ({
  loginButton,
  goToNextStep,
  createQEVError,
  createQEVLoading,
  hasSearchPreferences,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors: formErrors },
    trigger,
    clearErrors,
    control,
  } = useFormContext<TFormValues>()

  const {
    data: citiesData,
    loading: citiesLoading,
    error: citiesError,
  } = useQuery<CitiesQuery>(GET_CITIES, {
    ssr: false,
  })

  const [
    getDistricts,
    { data: districtsData, loading: districtsLoading, error: districtsError },
  ] = useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(GET_DISTRICTS, {
    ssr: false,
    fetchPolicy: 'cache-first',
  })

  const handleChangeCurrencyInput = (
    e: ChangeEvent<HTMLInputElement>,
    field: keyof TFormValues,
  ) => {
    const { value } = e.target
    const validCurrency = toValidNumber(value) as number

    if (validCurrency) {
      const formattedValue = toCurrencyNoStyle(validCurrency)
      setValue(field, formattedValue)
    }
  }

  const values = getValues()
  const { name, phoneNumber, email } = values
  const hasProfile = Boolean(name && phoneNumber && email)
  const submitButton = {
    label:
      (hasSearchPreferences && hasProfile && 'Atualizar perfil') ||
      (hasProfile && 'Finalizar cadastro') ||
      'Avançar',
    onClick: () => void goFoward(),
    props: {
      disabled: createQEVLoading,
    },
  }

  const queryCities = useMemo(
    () => citiesData?.cities || [],
    [citiesData?.cities],
  ) as City[]
  const orderCities = orderCitiesByVolume(queryCities)
  const citiesTypeaheadOptions = orderCities?.map((city) => ({
    value: city?.nameSlug ?? '',
    label: city?.name ?? '',
    Icon: {
      Component: IconPin,
    },
  }))

  const districtsTypeaheadOptions = useMemo(
    () =>
      districtsData?.districts?.map((district) => ({
        value: district?.nameSlug ?? '',
        label: district?.name ?? '',
        Icon: {
          Component: IconPin,
        },
      })) || [],
    [districtsData?.districts],
  )

  const selectedCity = useWatch({
    control,
    name: 'city',
  })

  const selectedNeighborhoods = useWatch({
    control,
    name: 'neighborhoods',
  })

  const selectedState = useMemo(
    () =>
      citiesData?.cities?.find((city) => city?.nameSlug === selectedCity?.value)
        ?.stateSlug,
    [citiesData?.cities, selectedCity],
  )

  const isValidCity = useMemo(
    () =>
      citiesTypeaheadOptions.some(
        (city) => city?.value === selectedCity?.value,
      ),
    [selectedCity?.value, citiesTypeaheadOptions],
  )

  const isDistrictsDisabled =
    ((districtsLoading || districtsError !== undefined) &&
      !getValues('neighborhoods')) ||
    !isValidCity

  const isCitiesDisabled = citiesLoading || citiesError !== undefined

  let greetings
  if (hasSearchPreferences) {
    greetings = name ? `${getFirstName(name)}, atualize` : 'Atualize'
  } else {
    greetings = name ? `${getFirstName(name)}, conte` : 'Conte'
  }

  const getInputStatus = (error?: string) => (error ? 'error' : undefined)

  const goFoward = useCallback(async () => {
    const valid = await trigger()

    if (valid) {
      events.onClickNextStepQEV()

      goToNextStep()
    }
  }, [])

  useEffect(() => {
    if (isValidCity) {
      void getDistricts({
        variables: { citySlug: selectedCity?.value, stateSlug: selectedState },
      })
    }
  }, [selectedCity?.value, selectedState, isValidCity])

  useEffect(() => {
    setValue('state', selectedState ?? '')
  }, [selectedState])

  useEffect(() => events.onLoadListingStepQEV(), [])

  useEffect(() => {
    if (isDistrictsDisabled) setValue('neighborhoods', [])
  }, [isDistrictsDisabled])

  return (
    <div className={styles.ecQEVAboutYouFormContainer}>
      <div>
        <Typography as="h2" variant="Title" bold>
          {greetings} sobre <span className={styles.ecQEVPink}>você</span> e
          receba recomendações de imóveis
          {hasSearchPreferences ? ' melhores.' : '.'}
        </Typography>
        <div className={styles.ecQEVFormWrapper}>
          <FormElement
            status={getInputStatus(formErrors.maxValue?.message)}
            title={{
              variant: 'label',
              value: 'Qual é seu orçamento para compra?',
              htmlFor: 'maxValue',
            }}
            helpText={{
              ariaDescribeId: 'minValue',
              value:
                formErrors.maxValue?.message || 'Valor mínimo de R$ 400.000',
            }}
            size="big"
          >
            <Input
              status={getInputStatus(formErrors.maxValue?.message)}
              leadingContent={{
                variant: 'text',
                value: 'R$',
              }}
              inputElementProps={{
                id: 'maxValue',
                'aria-describedby': 'entryValueHelpText',
                inputMode: 'numeric',
                onFocus: () =>
                  events.onFocusListingStepInputQEV(
                    'maxValue',
                    getValues('maxValue'),
                  ),
                ...register('maxValue', {
                  required: 'O preenchimento desse campo é obrigatório',
                  validate: {
                    minValue: (value) =>
                      (toValidNumber(value) as number) >= 400000 ||
                      'O valor mínimo deve ser de R$ 400.000',
                    maxValue: (value) =>
                      (toValidNumber(value) as number) <= 50000000 ||
                      'O valor máximo deve ser de R$ 50.000.000',
                  },
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    handleChangeCurrencyInput(e, 'maxValue')
                  },
                }),
              }}
            />
          </FormElement>

          <FormElement
            status={getInputStatus(formErrors.city?.message)}
            title={{
              variant: 'label',
              value: 'Em qual cidade você quer morar?',
              htmlFor: 'city',
            }}
            helpText={{
              ariaDescribeId: 'selectCity',
              value:
                formErrors.city?.message ||
                'Selecione uma das cidades em que atuamos',
            }}
            size="big"
          >
            <Controller
              control={control}
              name="city"
              rules={{
                required: 'O preenchimento desse campo é obrigatório',
              }}
              render={({ field: { onChange, value } }) => (
                <Typeahead
                  id="cities"
                  onSelect={(item) => {
                    // Reset neighborhoods if city changes
                    if (value?.value !== item.value)
                      setValue('neighborhoods', [])

                    clearErrors('city')
                    onChange(item)
                  }}
                  defaultValue={value?.label}
                  options={citiesTypeaheadOptions}
                  inputStatus={getInputStatus(formErrors.city?.message)}
                  disabled={isCitiesDisabled}
                  inputProps={{
                    placeholder: 'Digite a cidade',
                    onChange: ({ target }) => {
                      // Reset neighborhoods if city changes and reset city if empty value
                      if (!target.value) {
                        setValue('neighborhoods', [])
                        setValue('city', null)
                      }
                    },
                    onFocus: () =>
                      events.onFocusListingStepInputQEV(
                        'city',
                        getValues('city.label'),
                      ),
                  }}
                />
              )}
            />
          </FormElement>

          <FormElement
            status={getInputStatus(formErrors.neighborhoods?.message)}
            title={{
              variant: 'label',
              value: 'Selecione 1 ou mais bairros para você morar',
              htmlFor: 'neighborhood',
            }}
            helpText={{
              ariaDescribeId: 'selectNeighborhood',
              // eslint-disable-next-line no-nested-ternary
              value: formErrors.neighborhoods
                ? formErrors.neighborhoods?.message
                : !isValidCity || districtsLoading || districtsError
                ? 'Selecione uma cidade antes'
                : 'Selecione um ou mais bairros em que atuamos',
            }}
            size="big"
          >
            <Controller
              control={control}
              name="neighborhoods"
              rules={{
                required: 'O preenchimento desse campo é obrigatório',
              }}
              render={({ field: { onChange, value } }) => (
                <Typeahead
                  id="neighborhoods"
                  defaultMultiselectValue={value}
                  multiselect
                  disabled={isDistrictsDisabled}
                  onSelect={(items) => {
                    clearErrors('neighborhoods')
                    onChange(items)
                  }}
                  options={districtsTypeaheadOptions}
                  inputStatus={getInputStatus(
                    formErrors.neighborhoods?.message,
                  )}
                  inputProps={{
                    onFocus: () =>
                      events.onFocusListingStepInputQEV(
                        'neighborhoods',
                        typeof selectedNeighborhoods === 'string'
                          ? selectedNeighborhoods
                          : (selectedNeighborhoods || [])?.map(
                              ({ label }) => label,
                            ),
                      ),
                    placeholder: 'Busque o bairro',
                  }}
                />
              )}
            />
          </FormElement>
          {createQEVError && (
            <div className={styles.ecQEVBannerError}>
              <Typography as="p" variant="Paragraph">
                Erro ao enviar. Revise os seus dados e tente novamente
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div>
        <FAQ loginButton={loginButton} submitButton={submitButton} />
      </div>
    </div>
  )
}

export default AboutYou
