import {
  InputHTMLAttributes,
  FC,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react'
import classNames from 'classnames'
import Button from 'components/Button'
import uniqBy from 'lodash/uniqBy'
import { IconSearch, IconArrowLeft } from 'icons'
import Input from 'components/form/Input'
import { TStatus as TInputStatus } from 'components/form/Input/types'
import { TIconProps } from 'icons/types'
import useEventListener from 'hooks/useEventListener'
import useClickOutside from 'hooks/useClickOutside'
import useFocusOutside from 'hooks/useFocusOutside'
import { slugify } from 'lib/text'
import { isIOS } from 'lib/device'
import { delayFocus } from 'lib/dom'
import Suggestions, {
  TProps as TSuggestionsProps,
  TItem,
  TItemList,
} from './components/Suggestions'
import styles from './Typeahead.module.scss'
import Tag from './components/Tag'

type TInputProps = InputHTMLAttributes<HTMLInputElement>
type TPickInputProps = Pick<
  TInputProps,
  'id' | 'placeholder' | 'value' | 'onChange' | 'onFocus'
>

type TCommonProps = {
  id: string
  inputProps?: TPickInputProps
  inputIcon?: FC<TIconProps>
  className?: string
  disabled?: boolean
  disabledFilter?: boolean
  defaultValue?: string
  defaultMultiselectValue?: TItemList
  inputStatus?: TInputStatus
  showOpenButton?: boolean
  onClear?: () => void
  onOpenSuggestions?: () => void
  onOpenButtonClick?: () => void
} & Pick<
  TSuggestionsProps,
  | 'onSelectLocation'
  | 'options'
  | 'optionsInfo'
  | 'optionsClassName'
  | 'showSelectionInfo'
>

type TConditionalProps =
  | {
      multiselect: true
      onSelect: (item: TItemList) => void
    }
  | {
      multiselect?: false
      onSelect: (item: TItem) => void
    }

export type TProps = TCommonProps & TConditionalProps

const Typeahead: FC<TProps> = ({
  optionsClassName,
  inputProps,
  id,
  options,
  disabled,
  disabledFilter,
  defaultValue,
  defaultMultiselectValue,
  multiselect,
  inputStatus,
  className,
  showOpenButton,
  onOpenSuggestions,
  onSelect,
  onSelectLocation,
  onClear,
  onOpenButtonClick,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)
  const [value, setValue] = useState(defaultValue || '')
  const [preSelected, setPreSelected] = useState<number | null>(null)
  const [multiselectValue, setMultiselectValue] = useState<TItemList>(
    defaultMultiselectValue || [],
  )

  const isMultiselectArray = Array.isArray(multiselectValue)

  const suggestionsProps = rest as TSuggestionsProps

  const handleClose = () => {
    setIsVisible(false)
    setPreSelected(null)
  }

  const handleSelect = (item: TItem) => {
    if (multiselect) {
      const list = uniqBy(
        [
          ...multiselectValue,
          { label: item.label, value: item.value, Icon: item.Icon },
        ],
        'value',
      )
      setMultiselectValue(list)
      setValue('')
      if (onSelect) onSelect(list)
    } else {
      setValue(item.label)
      if (onSelect) onSelect(item)
    }

    handleClose()
  }

  const handleRemoveItem = (selectedValue: string) => {
    if (!multiselect) return

    const list = multiselectValue.filter((item) => selectedValue !== item.value)

    setMultiselectValue(list)

    if (onSelect) onSelect(list)
  }

  const handleSelectLocation = () => {
    if (onSelectLocation) {
      onSelectLocation()
      setValue('Imóveis próximos')
      handleClose()
    }
  }

  const handleEscape = (event: Event) => {
    if ((event as KeyboardEvent).key === 'Escape' && isVisible) {
      handleClose()
    }
  }

  const handleFocus = (target: HTMLInputElement) => {
    if (isIOS()) {
      delayFocus(target)
    }
  }

  const handleOpenButtonClick = () => {
    if (onOpenButtonClick) onOpenButtonClick()
    ref.current?.querySelector('input')?.focus()
  }

  useEventListener({
    type: 'keydown',
    listener: handleEscape,
  })

  useClickOutside({
    element: ref,
    listener: handleClose,
  })

  useFocusOutside({
    element: ref,
    listener: handleClose,
  })

  const filterOptions = useMemo(
    () =>
      options.filter((item) => {
        if (value === 'Imóveis próximos') return true

        return item.value.includes(slugify(value))
      }),
    [value, options],
  )

  useEffect(() => {
    if (disabled) {
      setValue('')
      setMultiselectValue([])
    }
  }, [disabled])

  useEffect(() => {
    if (!disabled) setValue(defaultValue ?? '')
  }, [defaultValue, disabled])

  useEffect(() => {
    if (!disabled) setMultiselectValue(defaultMultiselectValue ?? [])
  }, [defaultMultiselectValue, disabled])

  const currentOptions = disabledFilter ? options : filterOptions

  const typeaheadClassName = classNames(styles.ecTypeahead, className, {
    [styles.ecTypeaheadVisible]: isVisible,
  })

  const headerdClassName = classNames(styles.ecTypeaheadHeader, {
    [styles.ecTypeaheadHeaderVisible]: isVisible,
  })

  const backButtonClassName = classNames(styles.ecTypeaheadBackButton, {
    [styles.ecTypeaheadBackButtonVisible]: isVisible,
  })

  const openButtonClassName = classNames(styles.ecTypeaheadOpenButton, {
    [styles.ecTypeaheadOpenButtonVisible]: !value,
    [styles.ecTypeaheadOpenButtonModal]: isVisible,
  })

  const multiselectClassName = classNames(styles.ecMultiselectWrapper, {
    [styles.ecMultiselectWrapperHidden]: isVisible,
  })

  return (
    <div
      ref={ref}
      data-testid={`ecTypeahead-${id}`}
      className={typeaheadClassName}
    >
      <div className={headerdClassName}>
        <Button
          className={backButtonClassName}
          theme="white"
          shape="circle"
          aria-label="Fechar opções"
          Icon={{
            Component: IconArrowLeft,
            title: 'Ícone: voltar',
            color: 'grey900',
          }}
          onClick={() => setIsVisible(false)}
        />
        <Input
          status={inputStatus}
          allowClear={typeof onClear === 'function' && Boolean(value)}
          onClear={() => {
            if (onClear) onClear()
            setValue('')
          }}
          leadingContent={
            !showOpenButton
              ? {
                  variant: 'icon',
                  Icon: {
                    Component: IconSearch,
                    title: 'Ícone: Busca',
                    color: 'grey500',
                  },
                }
              : undefined
          }
          inputElementProps={{
            ...inputProps,
            onFocusCapture: (event) => {
              if (!isVisible) handleFocus(event.target as HTMLInputElement)
              setIsVisible(true)
              if (onOpenSuggestions) onOpenSuggestions()
            },
            onChange: (event) => {
              setValue(event.target.value)
              if (inputProps?.onChange) inputProps.onChange(event)
            },
            value,
            autoComplete: 'off',
            id: `typeaheadInput-${id}`,
            role: 'combobox',
            onKeyDown: (event) => {
              switch (event.key) {
                case 'Down': // IE/Edge specific value
                case 'ArrowDown': {
                  setIsVisible(true)
                  if (
                    preSelected !== null &&
                    preSelected < currentOptions.length - 1
                  ) {
                    setPreSelected(preSelected + 1)
                  } else if (onSelectLocation) {
                    setPreSelected(-1)
                  } else {
                    setPreSelected(0)
                  }
                  break
                }
                case 'Up': // IE/Edge specific value
                case 'ArrowUp': {
                  setIsVisible(true)
                  if (
                    preSelected !== null &&
                    onSelectLocation &&
                    preSelected > -1
                  ) {
                    setPreSelected(preSelected - 1)
                  } else if (preSelected !== null && preSelected > 0) {
                    setPreSelected(preSelected - 1)
                  } else {
                    setPreSelected(currentOptions.length - 1)
                  }
                  break
                }
                case 'Enter': {
                  if (preSelected === -1 && onSelectLocation)
                    handleSelectLocation()
                  else if (preSelected !== null)
                    handleSelect(currentOptions[preSelected])
                  break
                }
                default:
                  break
              }
            },
            disabled,
            'aria-expanded': isVisible,
            'aria-autocomplete': 'list',
            'aria-controls': `list-${id}`,
          }}
        />
        {showOpenButton && (
          <button
            type="button"
            className={openButtonClassName}
            onClick={handleOpenButtonClick}
            aria-label="Abrir opções"
            aria-hidden={!!value}
          >
            <span className={styles.ecTypeaheadOpenButtonIcon}>
              <IconSearch
                title="Ícone: Busca"
                color="white"
                width={18}
                height={18}
              />
            </span>
          </button>
        )}
      </div>
      <Suggestions
        {...suggestionsProps}
        isVisible={isVisible}
        preSelected={preSelected}
        id={id}
        options={currentOptions}
        onSelect={handleSelect}
        onSelectLocation={onSelectLocation && handleSelectLocation}
        optionsClassName={classNames(
          styles.ecTypeaheadSuggestions,
          optionsClassName,
        )}
      />
      {multiselect && multiselectValue.length > 0 && isMultiselectArray && (
        <div className={multiselectClassName}>
          {multiselectValue.map((item) => (
            <Tag
              key={item.label}
              label={item.label}
              value={item.value}
              onCloseClick={handleRemoveItem}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Typeahead
