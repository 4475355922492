import { gql } from '@apollo/client'

export default gql`
  mutation simplifiedAboutYouBuyerLeadCreate(
    $input: SimplifiedAboutYouBuyerLeadInput!
  ) {
    simplifiedAboutYouBuyerLeadCreate(input: $input) {
      maxValue
      city
      neighborhoods
      name
      phoneNumber
      email
      credentials {
        jwt
      }
    }
  }
`
