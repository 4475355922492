import { atom, useAtom } from 'jotai'
import { TFormValues } from '../types'

type TUseQEVModalProps = {
  isMounted: boolean
  isVisible: boolean
  isRedirecting?: boolean
  defaultValues?: TFormValues
}

const QEVModalAtom = atom<TUseQEVModalProps>({
  isMounted: false,
  isVisible: false,
})

const useQEVModal = () => {
  const [QEVModal, setQEVModal] = useAtom(QEVModalAtom)

  return {
    QEVModal,
    setQEVModal,
  }
}

export default useQEVModal
