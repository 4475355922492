import { gql } from '@apollo/client'

export default gql`
  query cities {
    cities {
      name
      nameSlug
      state
      stateSlug
      lat
      lng
    }
  }
`
