import { FC, ReactNode } from 'react'
import classNames from 'classnames'
import { IconAim, IconInfoCircle } from 'icons'
import { TIconComponent } from 'icons/types'
import Typography from 'components/Typography'
import styles from './Suggestions.module.scss'
import { getAppVersion } from 'lib/experiment'

export type TItem = {
  value: string
  label: string
  Icon?: Omit<TIconComponent, 'title' | 'color' | 'width' | 'height'>
}

export type TItemList = Array<TItem>

export type TProps = {
  options: TItemList
  isVisible?: boolean
  onSelect?: (item: TItem) => void
  onSelectLocation?: () => void
  showSelectionInfo?: boolean
  preSelected?: number | null
  optionsClassName?: string
  optionsInfo?: string | ReactNode
  id: string
}

const ItemIcon: FC<{ item: TItem }> = ({ item }) => {
  if (!item.Icon) return null
  const { Component } = item.Icon
  return <Component color="grey900" title={item.label} width={18} height={18} />
}

const Suggestions: FC<TProps> = ({
  options,
  optionsInfo,
  isVisible,
  onSelect,
  onSelectLocation,
  showSelectionInfo,
  preSelected,
  id,
  optionsClassName,
}) => {
  const isWebview = !!getAppVersion()

  return (
    <div
      className={classNames(styles.ecSuggestionsContainer, optionsClassName)}
      aria-hidden={!isVisible}
      id={`typeaheadSuggestions-${id}`}
      data-testid={`typeaheadSuggestions-${id}`}
    >
      {optionsInfo ? (
        <div className={styles.ecSuggestionsInfo}>{optionsInfo}</div>
      ) : null}
      <div
        className={classNames(styles.ecSuggestionsScroll, {
          [styles.ecSuggestionsScrollNoResults]: !options.length,
        })}
      >
        {showSelectionInfo && (
          <div className={styles.ecSuggestionsTip}>
            <IconInfoCircle color="grey900" title="Dica" width={14} />
            <Typography variant="Label">
              Selecione uma das <strong>opções abaixo</strong>
            </Typography>
          </div>
        )}
        <ul className={styles.ecSuggestions} role="listbox" aria-label={id}>
          {onSelectLocation && !isWebview ? (
            <li
              role="option"
              aria-selected={preSelected === -1}
              tabIndex={-1}
              className={classNames(styles.ecSuggestionItem, {
                [styles.ecPreSelected]: preSelected === -1,
              })}
              onClick={onSelectLocation}
            >
              <IconAim
                width={18}
                color="grey900"
                title="Ícone: Ver imóveis próximos"
              />
              <Typography>Ver imóveis próximos</Typography>
            </li>
          ) : null}
          {options.map((item, key) => (
            <li
              role="option"
              key={item.value}
              className={classNames(styles.ecSuggestionItem, {
                [styles.ecPreSelected]: preSelected === key,
              })}
              aria-selected={preSelected === key}
              onClick={() => {
                if (onSelect) onSelect(item)
              }}
            >
              <ItemIcon item={item} />
              {item.label}
            </li>
          ))}
        </ul>
      </div>
      {!options.length && (
        <div className={styles.ecSuggestionItem}>Nenhum resultado</div>
      )}
    </div>
  )
}

export default Suggestions
