import { RefObject } from 'react'
import getRefElement from 'lib/getRefElement'
import useEventListener from './useEventListener'

interface UseClickOutside {
  listener: (event: Event) => void
  element: RefObject<HTMLElement>
}

const useClickOutside = ({ listener, element }: UseClickOutside): void => {
  const handleOutsideClick = (event: Event) => {
    const el = getRefElement(element)
    if (!el || (el as HTMLElement).contains(event.target as Node)) return

    listener(event)
  }

  useEventListener({
    type: 'mousedown',
    listener: handleOutsideClick,
  })
}

export default useClickOutside
