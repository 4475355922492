import { gql } from '@apollo/client'

export default gql`
  query districts($citySlug: String, $stateSlug: String) {
    districts(citySlug: $citySlug, stateSlug: $stateSlug) {
      state
      stateSlug
      city
      citySlug
      name
      nameSlug
      uuid
    }
  }
`
