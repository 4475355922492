import { FC } from 'react'
import { IconCross } from 'icons'
import styles from './Tag.module.scss'

type TProps = {
  label: string
  value: string
  onCloseClick: (value: string) => void
}

const Tag: FC<TProps> = ({ label, value, onCloseClick }) => {
  return (
    <div className={styles.tag} data-testid="ecTypeaheadTag">
      {label}
      <button
        onClick={() => onCloseClick(value)}
        type="button"
        className={styles.closeButton}
      >
        <IconCross color="grey900" title="remover" width={24} height={24} />
      </button>
    </div>
  )
}

export default Tag
