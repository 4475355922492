import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { deleteCookie } from 'cookies-next'
import useStep from 'hooks/useStep'
import {
  SimplifiedAboutYouBuyerLeadCreateMutation,
  SimplifiedAboutYouBuyerLeadCreateMutationVariables,
} from 'graphql/types'
import CREATE_QEV from 'graphql/mutations/simplifiedAboutYouBuyerLeadCreate'
import { clearApolloStore, onUserLoginWebviewMessage, setJwt } from 'lib/user'
import { toValidNumber } from 'lib/number'
import logger from 'lib/logger'
import { getUTMObject } from 'lib/utm'
import * as events from './events'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { IconArrowLeft } from 'icons'
import AboutYou from './Steps/AboutYou'
import styles from './QEV.module.scss'
import { TFormValues, TQEVVariables } from './types'
import Register from './Steps/Register'
import Success from './Steps/Success'
import { TLoginButtonProps } from './components/FAQ'
import classNames from 'classnames'

type TStep = {
  content: ReactNode
}

export type TProps = {
  defaultValues?: TFormValues
  isModalVisible: boolean
  onClose: () => void
  onCompleted?: (successScreenData: TQEVVariables | undefined) => void
  loginButton?: TLoginButtonProps
  successButton?: {
    label: string
    onClick: (successScreenData: TQEVVariables | undefined) => void
  }
  hasSearchPreferences?: boolean | null
}

const QEV: FC<TProps> = ({
  defaultValues,
  isModalVisible = false,
  onClose,
  onCompleted,
  loginButton,
  successButton,
  hasSearchPreferences,
}) => {
  const [successScreenData, setSuccessScreenData] = useState<TQEVVariables>()

  const useFormMethods = useForm<TFormValues>({
    mode: 'onChange',
    defaultValues,
  })
  const [createQEV, { error: createQEVError, loading: createQEVLoading }] =
    useMutation<
      SimplifiedAboutYouBuyerLeadCreateMutation,
      SimplifiedAboutYouBuyerLeadCreateMutationVariables
    >(CREATE_QEV)

  const { goToNextStep, goToPrevStep, currentStep, setStep } = useStep(4)

  const getMutationVariables = () => {
    const values = useFormMethods.getValues()
    return {
      ...values,
      city: values?.city?.label as string,
      neighborhoods: values?.neighborhoods?.map(
        (neighborhood) => neighborhood?.label,
      ),
      maxValue: toValidNumber(values?.maxValue) as number,
      utm: getUTMObject() as Record<string, string | null>,
    }
  }

  const handleOnClose = () => {
    onClose()
    useFormMethods.reset()

    if (currentStep >= 3) {
      void clearApolloStore(
        onCompleted ? () => onCompleted(successScreenData) : undefined,
      )
    }

    setStep(1)
  }

  const handleNextStep = useCallback(async () => {
    const { name, phoneNumber, email } = useFormMethods.getValues()
    const hasProfile = Boolean(name && phoneNumber && email)

    if (
      (hasSearchPreferences && hasProfile) ||
      hasProfile ||
      currentStep === 2
    ) {
      try {
        const valid = await useFormMethods.trigger()
        if (valid) {
          events.onSubmitQEV()

          const variables = getMutationVariables()

          const QEVData = await createQEV({
            variables: {
              input: variables,
            },
          })

          deleteCookie('showRecommendationsLoading')

          events.onSendQEV(variables)

          if (!QEVData.data?.simplifiedAboutYouBuyerLeadCreate) return
          setSuccessScreenData(variables)

          const jwt =
            QEVData?.data?.simplifiedAboutYouBuyerLeadCreate?.credentials?.jwt
          if (jwt) {
            setJwt(jwt)
            onUserLoginWebviewMessage(jwt)
          }

          if (hasSearchPreferences) {
            events.onUpdateSendSuccessQEV(variables)
          } else {
            events.onSendSuccessQEV(variables)
          }

          if (hasSearchPreferences) {
            onClose()
            useFormMethods.reset()

            void clearApolloStore(
              onCompleted ? () => onCompleted(successScreenData) : undefined,
            )

            setStep(1)
          } else {
            if (hasProfile) {
              setStep(3)
            } else {
              goToNextStep()
            }
          }
        }
      } catch (e) {
        const variables = getMutationVariables()
        events.onSendErrorQEV(JSON.stringify(e, null, 2), variables)
        logger.capture(e)
      }
    } else {
      goToNextStep()
    }
  }, [])

  const steps: Record<number, TStep> = {
    1: {
      content: (
        <AboutYou
          loginButton={loginButton}
          goToNextStep={() => void handleNextStep()}
          createQEVError={createQEVError}
          createQEVLoading={createQEVLoading}
          hasSearchPreferences={hasSearchPreferences}
        />
      ),
    },
    2: {
      content: (
        <Register
          loginButton={loginButton}
          goToNextStep={() => void handleNextStep()}
          createQEVError={createQEVError}
          createQEVLoading={createQEVLoading}
        />
      ),
    },
    3: {
      content: (
        <Success
          data={successScreenData}
          button={successButton}
          closeModal={handleOnClose}
        />
      ),
    },
  }

  const currentStepObject = steps[currentStep]

  const modalClassname = classNames(styles.ecQEVModalContainer, {
    [styles.ecQEVModalContainerHasFAQ]: currentStep === 1 || currentStep === 2,
    [styles.ecQEVModalContainerFullHeight]: currentStep === 3,
  })

  useEffect(() => {
    useFormMethods.reset(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <Modal
      visible={isModalVisible}
      header={{
        title: 'Perfil de compra',
        leadingContent: currentStep === 2 && (
          <Button
            aria-label="Voltar"
            theme="ghost"
            shape="circle"
            onClick={goToPrevStep}
            Icon={{
              Component: IconArrowLeft,
              color: 'grey900',
              title: 'Voltar',
            }}
          />
        ),
      }}
      handleBackgroundClick={handleOnClose}
      handleCloseButtonClick={handleOnClose}
      testId="ecQEVModal"
      width="bigger"
      fullHeight
      disableBackgroundScroll
      disableContainerScroll
    >
      <FormProvider {...useFormMethods}>
        <form
          className={modalClassname}
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          {currentStepObject.content}
        </form>
      </FormProvider>
    </Modal>
  )
}

export default QEV
